import React from 'react'
import styled from '@emotion/styled'
import { breakpoint, calcRem } from '@the-game/components/utils'
import FullWestAustralianLogo from '../../logos/FullWestAustralianLogo'

const headerBreakpoint = breakpoint('md')

export interface TheWestHeaderProps {
    className?: string | undefined
}

const StyledWestLogoContainer = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    margin: '0 auto',
    alignItems: 'center',
    padding: theme.spacing.xs,

    [headerBreakpoint]: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

const StyledWestAustralianLogo = styled(FullWestAustralianLogo)({
    display: 'block',
    height: calcRem(13),
    [headerBreakpoint]: {
        height: 'auto',
        width: calcRem(200),
    },
})

export function TheWestHeader({ className }: TheWestHeaderProps) {
    return (
        <StyledWestLogoContainer className={className}>
            <a
                aria-label="The West Australian"
                href="https://thewest.com.au/?utm_medium=referral&utm_campaign=thegame&utm_content=header#logo/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <StyledWestAustralianLogo aria-hidden="true" title="" />
            </a>
        </StyledWestLogoContainer>
    )
}
