import styled from "@emotion/styled";
import {calcRem} from "@the-game/components/utils";
import {Card} from "../Card/Card";
import {breakpoint, breakpointMax} from "@the-game/components/utils";
export const StyledMarginCard = styled(Card)(({theme, isSummary}) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridArea: "margincard",
  textAlign: "center",
  alignItems: "center",
  minHeight: calcRem(50),
  width: "100%",
  padding: isSummary ? 0 : calcRem(theme.spacing.sm, 20),
  borderRadius: 0,
  borderTop: isSummary ? void 0 : `1px solid ${theme.colors.palette.neutral50}`,
  borderLeft: isSummary ? `1px solid ${theme.colors.palette.neutral50}` : void 0,
  boxShadow: "none",
  paddingTop: isSummary ? calcRem(theme.spacing.xs) : void 0,
  [breakpointMax("xs")]: {
    borderTop: `1px solid ${theme.colors.palette.neutral50}`,
    borderRadius: 0,
    borderLeft: "none"
  },
  [breakpointMax("sm")]: {
    paddingBottom: isSummary ? calcRem(theme.spacing.xs) : void 0,
    borderLeft: "none"
  },
  [breakpoint("sm")]: {
    paddingBottom: 0
  }
}));
export const StyledMarginResult = styled("span")({
  gridColumn: "span 2"
});
export const StyledSummaryMarginResult = styled(StyledMarginResult)(({theme}) => ({
  paddingTop: calcRem(theme.spacing.xs)
}));
