import { ScoreDTO, SportName } from '@the-game/api-interfaces'
import { Link } from 'react-router-dom'
import { ChevronLeft, Ladder } from '@the-game/components/icons'
import React from 'react'
import {
    StyledHeading,
    StyledScoreValue,
    StyledTypography,
    SyledScoreContainer,
    HiddenMd,
    HiddenSm,
    StyledLadderButtonContainer,
    StyledLadderContainer,
    StyledLadderButtonHeading,
    StyledLadderButton,
} from './TipScoreCard.styled'

interface TipScoreCardProps {
    scoreData: ScoreDTO | undefined
    sport: SportName
}

function ScoreValuePlaceholder(): JSX.Element {
    return <StyledScoreValue>—</StyledScoreValue>
}

export function TipScoreCard({ scoreData, sport }: TipScoreCardProps) {
    const { round = 0, score, matchesPlayed, margin, totalScore, totalMargin } =
        scoreData || {}

    const roundLabel = sport === 'Cricket' ? 'Round' : 'Round'
    const roundLabelAbbreviation = sport === 'Cricket' ? 'R' : 'R'
    const printedRoundNumber = round > 0 ? round : 1

    return (
        <>
            <SyledScoreContainer sport={sport}>
                <StyledHeading level="4" size="6">
                    Results
                </StyledHeading>
                <StyledTypography>
                    <HiddenMd
                        aria-label={`${roundLabel} ${printedRoundNumber}`}
                    >
                        {roundLabel} {printedRoundNumber} tips
                    </HiddenMd>
                    <HiddenSm
                        aria-label={`${roundLabel} ${printedRoundNumber}`}
                    >
                        {roundLabelAbbreviation}
                        {printedRoundNumber} tips
                    </HiddenSm>

                    {round > 0 ? (
                        <StyledScoreValue
                            aria-label={`(${score} out of ${matchesPlayed} tips
                                correct)`}
                        >
                            {score}/{matchesPlayed}
                        </StyledScoreValue>
                    ) : (
                        <ScoreValuePlaceholder />
                    )}
                </StyledTypography>
                <StyledTypography>
                    <HiddenMd
                        aria-label={`${roundLabel} ${printedRoundNumber}`}
                    >
                        {`${roundLabel} `}
                        {printedRoundNumber}{' '}
                        {sport === 'Cricket' ? 'sixes' : 'margin'}
                    </HiddenMd>
                    <HiddenSm
                        aria-label={`${roundLabel} ${printedRoundNumber}`}
                    >
                        {roundLabelAbbreviation}
                        {printedRoundNumber}{' '}
                        {sport === 'Cricket' ? 'sixes' : 'margin'}
                    </HiddenSm>

                    {score ? (
                        <StyledScoreValue>{margin}</StyledScoreValue>
                    ) : (
                        <ScoreValuePlaceholder />
                    )}
                </StyledTypography>
                <StyledTypography>
                    Total tips{' '}
                    {round > 0 ? (
                        <StyledScoreValue>{totalScore}</StyledScoreValue>
                    ) : (
                        <ScoreValuePlaceholder />
                    )}
                </StyledTypography>
                <StyledTypography>
                    Total {sport === 'Cricket' ? 'sixes ' : 'margin '}
                    {round > 0 ? (
                        <StyledScoreValue>{totalMargin}</StyledScoreValue>
                    ) : (
                        <ScoreValuePlaceholder />
                    )}
                </StyledTypography>
            </SyledScoreContainer>
            <StyledLadderContainer>
                <Link to={`${sport}/ladder`}>
                    <StyledLadderButtonContainer>
                        <StyledLadderButton>
                            <Ladder aria-hidden size="sm" themeColor="black" />
                            <StyledLadderButtonHeading level="4" size="6">
                                Ladder
                            </StyledLadderButtonHeading>
                        </StyledLadderButton>
                        <ChevronLeft
                            aria-hidden
                            title=""
                            themeColor="black"
                            size="xs"
                            rotate={180}
                        />
                    </StyledLadderButtonContainer>
                </Link>
            </StyledLadderContainer>
        </>
    )
}
