import React, { useCallback, useEffect } from 'react'
import FocusTrap from 'focus-trap-react'
import { Backdrop } from '../Backdrop'
import { Card } from '../Card/Card'
import styled from '@emotion/styled'

const focusTrapOptions: FocusTrap['props']['focusTrapOptions'] = {
    // when the modal is unmounted, it will deactivate the focus trap
    // we don't need to deactivate it on ESC, because either:
    // a) we want the modal to close with ESC, so it will be deactivated anyway, or
    // b) we don't want the modal to close, so it should not deactivate the focus trap
    escapeDeactivates: false,
}

const escapeCode = 'Escape'

const StyledModal = styled('div')(({ theme }) => ({
    position: 'fixed',
    width: ' 100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.modal,
    top: 0,
    left: 0,
}))

// we need something that can accept a ref, so using a div, not an emotion component
const StyledContainer = styled('div')(({ theme }) => ({
    zIndex: theme.zIndex.modal,
}))

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: `calc(100vw - ${theme.spacing.md}px)`,
    padding: theme.spacing.lg,
}))

export function Modal({
    active = true,
    children,
    close,
}: {
    active?: boolean
    children: React.ReactNode
    close?: () => void
}) {
    const handleEscape = useCallback(
        (event: KeyboardEvent) => {
            if (event.code === escapeCode && close) {
                close()
            }
        },
        [close],
    )
    useEffect(() => {
        if (!close) {
            return
        }
        window.addEventListener('keydown', handleEscape, true)
        return () => {
            window.removeEventListener('keydown', handleEscape, true)
        }
    }, [close, handleEscape])

    return (
        <StyledModal>
            <Backdrop />
            <FocusTrap active={active} focusTrapOptions={focusTrapOptions}>
                <StyledContainer>
                    <StyledCard>{children}</StyledCard>
                </StyledContainer>
            </FocusTrap>
        </StyledModal>
    )
}
