import React from 'react'
import { Button } from '../Button'
import {
    calcRem,
    focusStyle,
    breakpoint,
    fontRemScale,
} from '@the-game/components/utils'
import { ChevronLeft } from '../Icons/ChevronLeft'
import { ChevronDown } from '../Icons/ChevronDown'
import { Select } from '../Select/Select'
import { Skeleton } from '../Skeleton'
import styled from '@emotion/styled'
import { VisuallyHidden } from '../VisuallyHidden'
import { SportName } from '@the-game/api-interfaces'
import { modifyLuminance } from '../utils/modifyLuminance'

export interface RoundNavigatorProps {
    totalRounds: number
    value: number | undefined
    leaderboard?: boolean
    sport: SportName
    onChange: (value: number) => void
}

const StyledGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: `${calcRem(56)} auto ${calcRem(56)} `,
    gridTemplateRows: calcRem(56),
    gridGap: theme.spacing.xxs,
    button: {
        width: '100%',
        height: '100%',
    },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontSize: fontRemScale(1.5),
    backgroundImage: 'none',
    padding: 0,
    paddingLeft: theme.spacing.md,
    fontWeight: theme.weights.bold,
    display: 'flex',
    flexAlign: 'center',
    width: '100%',
    height: '100%',
    border: 'none',
    position: 'absolute',
    transition: 'all 0.1s ease-in-out',
    option: {
        backgroundColor: theme.colors.white,
        color: theme.colors.palette.neutral90,
    },
    '&:focus': {
        backgroundColor: modifyLuminance(theme.colors.primary, 50),
    },

    ...focusStyle(theme),

    '&:hover': {
        backgroundColor: modifyLuminance(theme.colors.primary, 50),
        ...theme.elevations.Elevation2,
    },
    [breakpoint('md')]: {
        textAlignLast: 'right',
        paddingRight: `calc(50% - ${calcRem(52)})`,
        paddingLeft: `calc(50% - ${calcRem(65)})`,
    },
}))

const StyledFlex = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    svg: {
        position: 'absolute',
        right: 20,
    },

    [breakpoint('md')]: {
        svg: {
            position: 'absolute',
            right: `calc(50% - ${calcRem(80)})`,
        },
    },
})

function RoundNavigatorSkeleton() {
    const props = {
        width: '100%',
        height: '100%',
    }
    return (
        <StyledGrid>
            <Skeleton {...props} /> <Skeleton {...props} />{' '}
            <Skeleton {...props} />
        </StyledGrid>
    )
}

export function RoundNavigator({
    value,
    totalRounds,
    leaderboard = false,
    sport,
    onChange,
}: RoundNavigatorProps) {
    const rounds = React.useMemo(() => {
        const knownRounds = new Set(
            new Array(totalRounds).fill(null).map((_, i) => i + 1),
        ) // 1, 2, 3, ..., {totalRounds - 1}, {totalRounds}
        if (value) {
            knownRounds.add(value)
        }
        return [...knownRounds.values()]
    }, [totalRounds, value])

    if (value === undefined) {
        return <RoundNavigatorSkeleton />
    }

    const thisIndex = rounds.indexOf(value)
    const roundLabel = sport === 'Cricket' ? 'Round' : 'Round'

    return (
        <StyledGrid>
            <Button
                shape="square"
                disabled={!leaderboard ? value === 1 : value === 0}
                onClick={() => onChange(rounds[thisIndex - 1])}
                padding={0}
            >
                <ChevronLeft size="xxs" themeColor="white" />
                <VisuallyHidden>Previous {roundLabel}</VisuallyHidden>
            </Button>
            <StyledFlex>
                <StyledSelect
                    value={value}
                    aria-label={roundLabel}
                    defaultOption={false}
                    onChange={({ currentTarget }) =>
                        onChange(Number(currentTarget.value))
                    }
                >
                    {!!leaderboard && (
                        <option key="season" value="0">
                            Season
                        </option>
                    )}
                    {rounds.map((num) => (
                        <option key={num} value={num}>
                            {roundLabel} {num}
                        </option>
                    ))}
                </StyledSelect>
                <ChevronDown
                    size="xs"
                    themeColor="white"
                    pointerEvents="none"
                />
            </StyledFlex>

            <Button
                shape="square"
                padding={0}
                onClick={() => onChange(value + 1)}
                disabled={value >= totalRounds}
            >
                <ChevronLeft
                    title="Chevron right icon"
                    size="xxs"
                    themeColor="white"
                    rotate={180}
                />
                <VisuallyHidden>Next {roundLabel}</VisuallyHidden>
            </Button>
        </StyledGrid>
    )
}
