import {Card} from "../Card/Card";
import styled from "@emotion/styled";
import {calcRem} from "@the-game/components/utils";
import {breakpoint} from "@the-game/components/utils";
export const StyledPromoCardContainer = styled("div")({
  margin: "auto",
  [breakpoint("sm")]: {
    padding: `${calcRem(90)} ${calcRem(80)} ${calcRem(70)} ${calcRem(80)}`
  },
  [breakpoint("md")]: {
    padding: 0,
    maxWidth: "70%"
  },
  [breakpoint("lg")]: {
    maxWidth: calcRem(1160)
  },
  marginBottom: calcRem(80)
});
export const StyledPromoCard = styled("div")({
  marginTop: calcRem(32),
  [breakpoint("sm")]: {
    marginTop: calcRem(48)
  },
  [breakpoint("lg")]: {
    marginTop: calcRem(76)
  }
});
export const StyledContent = styled("div")({
  padding: calcRem(8, 0, 0, 16),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "svg, img": {
    height: calcRem(40),
    width: calcRem(135)
  },
  [breakpoint("sm")]: {
    padding: calcRem(0, 0, 0, 16),
    alignItems: "start",
    marginRight: calcRem(16)
  }
});
export const StyledImage = styled("img")({
  width: calcRem(350),
  margin: "auto",
  marginBottom: calcRem(-12)
});
export const StyledCloseButton = styled("div")({
  fontsize: calcRem(48),
  position: "absolute",
  top: calcRem(2),
  right: calcRem(2),
  cursor: "pointer",
  [breakpoint("xs")]: {
    top: calcRem(8),
    right: calcRem(8)
  },
  [breakpoint("sm")]: {
    top: calcRem(16),
    right: calcRem(16)
  }
});
export const StyledCard = styled(Card)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing.sm,
  mergin: theme.spacing.sm,
  h3: {
    fontSize: calcRem(28),
    fontWeight: 600
  },
  p: {
    color: theme.colors.palette.neutral70,
    marginBottom: calcRem(16)
  },
  [breakpoint("sm")]: {
    flexDirection: "row",
    alignItems: "end",
    h3: {
      fontSize: calcRem(51)
    }
  }
}));
export const StyledBadgeGroup = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginBottom: calcRem(32),
  [breakpoint("sm")]: {
    marginBottom: 0
  }
});
